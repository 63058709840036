import React from "react"
import Section from "./section"
import ContactImage from "./contactImage";


const Contact = ({ texts, mapLink, emailAddress, emailSubject, emailBody, phoneNumber }) => (
  <Section name="contact">
    <div className="contact__wrapper">
      <h3>{texts.title}</h3>
      <p>{texts.subtitle}</p>
      <div className="box">
        <div className="contact-list">
          <span className="contact-list-item">
            <h5>Cím</h5>
            <p>
              <a href={mapLink} target="_blank" rel="noreferrer">
                Székely Csaba
                <br /> 2234 Maglód, Vörösmarty utca 17.
              </a>
            </p>
          </span>
          <span className="contact-list-item">
            <h5>Email</h5>
            <p>
              <a
                href={`mailto:${emailAddress}?subject=${emailSubject}&body=${emailBody}`}
                target="_blank"
                rel="noreferrer"
              >
                {emailAddress}
              </a>
            </p>
          </span>
          <span className="contact-list-item">
            <h5>Telefon</h5>
            <p>
              <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            </p>
          </span>
          <span className="contact-list-item">
            <h5>Nyitvatartás</h5>
            <p>Hétköznapokon 10 – 18</p>
          </span>
        </div>
        <a
          href={mapLink}
          target="_blank"
          rel="noreferrer"
          className="map-btn"
        >
          Térkép
        </a>
      </div>
    </div>
    <ContactImage />
  </Section>
)

export default Contact