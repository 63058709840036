import React from "react"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Logo from "./logo"
import Menu from "./menu"
import MobileMenu from "./mobileMenu"
import Hamburger from "./hamburger"

class Header extends React.Component {
  state = {
    scrollYThreshold: 5,
    scrollY: undefined,
    viewportHeight: undefined,
    activeMenu: '',

    mobileMenuOpened: false
  }

  scrollTops = [];

  componentDidMount() {
    this.setState((state)=>({viewportHeight: window.innerHeight}))
    this.setScrollTops()
    window.addEventListener('scroll', () => {
      this.handleScroll(window.scrollY)
    });
  }

  setScrollTops()
  {
    if (this.state.scrollY !== undefined) return true;

    const { site: { siteMetadata: { menu: { elements: menuElements } } } } = this.props.siteData;

    menuElements.forEach(el => {
      const elId = el.target.replace('#', '')
      const target = document.getElementById( `${elId}-section` )
      this.scrollTops.push({ id: elId, y: target.offsetTop })
    });

    this.setState(() => ({ activeMenu: this.scrollTops[0].id }))
  }

  handleScroll() {
    this.setState(() => ({ scrollY: window.scrollY }), () => this.setActiveMenu())
  }

  setActiveMenu() {
    const {scrollY} = this.state;
    let {activeMenu,viewportHeight} = this.state;

    this.scrollTops.forEach( section => {
      if ( scrollY >= section.y - viewportHeight / 2 ) {
        activeMenu = section.id;
      }
    });
    this.setState(() => ({ activeMenu }))
  }

  render() {
    const scrolledDown = this.state.scrollY >= this.state.scrollYThreshold;
    const { activeMenu, mobileMenuOpened } = this.state;

    if ( !this.props.siteData ) {
      return null;
    }

    const { site: { siteMetadata: { menu: { elements: menuElements } } } } = this.props.siteData;

    return (
      <header
        className={["header", ( scrolledDown ? "white" : "" ), (mobileMenuOpened ? "mobile-menu--opened" : "")].filter((s)=>s.length>0).join(' ')}
      >
        <Logo
          whiteLogo={!(scrolledDown||mobileMenuOpened)}
          onClick={(ev) => {ev.preventDefault(); scrollTo( `#landing` )} }
          role="button"
        />
        <Hamburger
          mobileMenuOpened={this.state.mobileMenuOpened}
          onClick={(ev) => {this.toggleMobileMenu()} }
        />

        <Menu elements={menuElements} activeMenu={activeMenu}/>
        <MobileMenu
          opened={this.state.mobileMenuOpened}
          elements={menuElements}
          activeMenu={activeMenu}
          onMenuItemClick={this.toggleMobileMenu}
        />
      </header>
    );
  }

  toggleMobileMenu = () => {
    this.setState((state)=>({
      mobileMenuOpened: !state.mobileMenuOpened
    }))
  }
}

export default Header