import React from "react"

const Section = ({ name, children }) => (
  <section
    className={name}
    id={`${name}-section`}
  >
    <a href={`#${name}`} id={`${name}`} name={name} className="section-anchor">&nbsp;</a>
    {children}
  </section>
)

export default Section