import React from "react"
import ArrowSvg from "../images/svg/arrow-h.svg"

const Arrow = ({extent,flipped}) => {
    return (
        <span className={[`arrow-${extent}-svg`,(flipped ? 'flipped' : '')].join(' ').trim()}>
            <ArrowSvg />
        </span>
    )
}

export default Arrow