import React from "react"
import { graphql } from 'gatsby'

import Landing from "../components/landing"
import About from "../components/about"
import Leathering from "../components/leathering"
import Works from "../components/works"
import Prices from "../components/prices"
import Faq from "../components/faq"
import Contact from "../components/contact"
import "normalize.css"
import "../styles/main.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"

class IndexPage extends React.Component {
  render()
  {
    const { data } = this.props;
    
    const {
      data: {
        site: {
          siteMetadata: {
            texts: { sections, emailSubject, emailBody, emailSubjectOnPrices, emailBodyOnPrices},
            links: { contactMapLink, contactEmailAddress, contactPhoneNumber },
            content: {
              prices: { list: priceList },
              works: { fbAlbumLink },
              faq: { list: faqList }
            },
          },
        },
      },
    } = this.props

    return (
      <Layout siteData={data}>
        <SEO title="Home" />

        <Landing texts={sections.landing} />
        <About texts={sections.about} />
        <Leathering texts={sections.leathering} />
        <Works
          texts={sections.works}
          fbAlbumLink={fbAlbumLink}
        />
        <Prices
          texts={sections.prices}
          priceList={priceList}
          emailAddress={contactEmailAddress}
          emailSubject={emailSubjectOnPrices}
          emailBody={emailBody}
        />
        <Faq
          texts={sections.faq}
          list={faqList}
        />
        <Contact
          texts={sections.contact}
          mapLink={contactMapLink}
          emailAddress={contactEmailAddress}
          emailSubject={emailSubject}
          emailBody={emailBodyOnPrices}
          phoneNumber={contactPhoneNumber}
        />
      </Layout>
    )
  }
}

export const menuQuery = graphql`
  query {
        site {
            siteMetadata {
                menu {
                    elements {
                        name, target, w
                    }
                }
                texts {sections{landing{p,h3}}}
                texts {sections{about{whitebox{head,content{left,right},tail},badge{b,p}}}}
                texts {sections{leathering{appointment,hours,waiting,complicated,wheels,shifters,h3,p}}}
                texts {sections{works{bgtext,galleryButton,fbpageButtonSup,fbpageButtonSub}}}
                texts {sections{contact{title,subtitle}}}
                texts {sections{prices{bgtext,redBoxTitle,redBoxContent,redBoxButtonLabel}}}
                texts {sections{faq{headerText,headerSubText}}}
                links {contactMapLink,contactEmailAddress,contactPhoneNumber}
                content { prices { list{label,price} } }
                content { works { fbAlbumLink } }
                texts { emailSubject,emailSubjectOnPrices,emailBody,emailBodyOnPrices }
                content { faq { list{title,content} } }
            }
        }
    }
`

export default IndexPage

// {
//   texts {
//     sections {
//       landing { p1, h3 }
//     }
//   }
// }