import React from "react"
import Section from "./section"
import FaqImage from './faqImage'
import Arrow from "./arrow"

class Faq extends React.Component {

  state = {
    index: 1,
    minIndex: 0,
    maxIndex: this.props.list.length - 1,

    topicsOpened: []
  }

  render() {
    const {texts} = this.props;
    const {index,minIndex,maxIndex} = this.state;

    return (
      <Section
        name="faq"
      >
        <div className="faq__wrap">
          <span className="faq__header">
            <h5>{texts.headerText}</h5>
            <p>{texts.headerSubText}</p>
          </span>
          <div className="faq__body">
            <div className="faq__list">
              <div
                className={["faq__list-item left",(index===minIndex?'hidden':'')].join(' ')}
                onClick={this.handleLeftClick}
                onKeyDown={()=>{}}
                role="button"
                aria-label="scroll left"
                tabIndex="0"
              >
                <ul className="topics">
                  {this.renderTopicList(0)}
                </ul>
              </div>
              {index>minIndex&&<div
                  className="arrow arrow-left"
                  onClick={this.handleLeftClick}
                  onKeyDown={()=>{}}
                  role="button"
                  aria-label="scroll left"
                  tabIndex="0"
                >
                  <Arrow extent="h"/>
                </div>}
              {index<=minIndex&&<div className="arrow muted"></div>}
              <div className="faq__list-item center">
                {this.renderTopicList(1)}
              </div>
              {index<maxIndex&&<div
                  className="arrow arrow-right"
                  onClick={this.handleRightClick}
                  onKeyDown={()=>{}}
                  role="button"
                  aria-label="scroll right"
                  tabIndex="0"
                >
                  <Arrow extent="h" flipped/>
                </div>}
              {index>=maxIndex&&<div className="arrow muted"></div>}
              <div
                className={["faq__list-item right",(index===maxIndex?'hidden':'')].join(' ')}
                onClick={this.handleRightClick}
                onKeyDown={()=>{}}
                role="button"
                aria-label="scroll right"
                tabIndex="0"
              >
                {this.renderTopicList(2)}
              </div>
            </div>
          </div>
          <div className="faq__body--mobile">
              {this.renderTopicListMobile()}
          </div>
        </div>
          <FaqImage />
      </Section>
    );
  }

  renderTopicList = (from) => {
    const list = this.props.list;

    const w = from === 1 ? 38 : 30;

    return (
      <div className="topic-list-over">
        <ul className="topic-list" style={{width: `${w*list.length}rem`, transform: `translateX(-${(((this.state.index-1)*w)+from*w)}rem)`}}>
          {list.map((topic,i)=>(
              <li key={i} className="topic">
                <em>{topic.title}</em>
                {topic.content}
              </li>
            ))}
        </ul>
      </div>
    )
  }

  renderTopicListMobile = () => {
    const list = this.props.list;
    const {topicsOpened} = this.state;

    return (
      <ul className="topic-list">
        {list.map((topic,i)=>(
            <li
              key={i}
              className={["topic", ( topicsOpened.indexOf(i) !== -1 ? "opened" : "" ) ].join(' ')}
              onClick={()=>this.handleTopicTap(i)}
              // onKeyDown={()=>{}}
              // role="button"
              // tabIndex="0"
            >
              <span className="t-title">{topic.title}</span>
              <span className="t-content">{topic.content}</span>
            </li>
          ))}
      </ul>
    )
  }

  handleTopicTap = (topicIndex) => {
    const {topicsOpened:actualTopicsOpened} = this.state;
    let topicsOpened;

    if ( actualTopicsOpened.indexOf(topicIndex) !== -1 )
    {
      topicsOpened = actualTopicsOpened.filter(i=>i!==topicIndex);
    }
    else {
      topicsOpened = actualTopicsOpened.concat([topicIndex]);
    }

    this.setState((state,props) => ({
      topicsOpened
    }));
  }

  handleLeftClick = () => {
    this.setState((state,props) => ({
      index: Math.max(state.index-1,state.minIndex)
    }))
  }

  handleRightClick = () => {
    this.setState((state,props) => ({
      index: Math.min(state.index+1,state.maxIndex)
    }))
  }
}

export default Faq