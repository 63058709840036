import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
// import Img from "gatsby-image/withIEPolyfill"

export default () => {
    const data = useStaticQuery(graphql`
        query {
            file(relativePath: {eq: "png/wheel.png"}) {
                childImageSharp {
                    fixed(width: 494) {
                    ...GatsbyImageSharpFixed
                    }
                }
            }
        }`
    )

    return (
        <div className="works__image">
            <Img fixed={data.file.childImageSharp.fixed} />
        </div>
    );
}