import React from "react"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Arrow from "./arrow"

const MobileMenu = ({elements,activeMenu,opened,onMenuItemClick}) => {
    return (
        <nav className={["header__mobile-menu", (opened ? "opened" : "")].join(' ')}>
            <ul>
                {Array.isArray(elements)&&elements.map(el=>{
                    const elId = el.target.replace('#','');
                    const className = `menu__item${elId === activeMenu ? " menu__item--active" : ""}`;
                    return (<li className={className} key={elId}>
                                <a href={el.target} onClick={
                                        (ev) => {
                                            ev.preventDefault();
                                            scrollTo( `${el.target}` )
                                            onMenuItemClick()
                                        }
                                    } style={{width: el.w}}>
                                    {el.name}
                                </a>
                                <Arrow extent="v"/>
                            </li>)
                })}
            </ul>
        </nav>
    );
}

export default MobileMenu