import React from "react"

import Header from "./header"

const Layout = ({ children, siteData }) => {
  return (
    <>
      <Header siteData={siteData} />
      <div className="wrap">
        <main>{children}</main>
      </div>
    </>
  )
}

export default Layout