import React from "react"
import Section from "./section"
import WorksImage from './worksImage'
import { Link } from 'gatsby'

const Works = ({texts,fbAlbumLink}) => (
  <Section
    name="works"
  >
    <em className="works__bgtext">{texts.bgtext}</em>
    <div className="works__wrap">
      <WorksImage />
      <div className="works__cta">
        <Link
          className="gallery_button"
          to="/gallery/"
          state={{
            modal: true
          }}
        >
          {texts.galleryButton}
        </Link>

        <a
          href={fbAlbumLink}
          target="_blank"
          rel="noreferrer"
          className="fbpage_button"
        >
          {texts.fbpageButtonSup}<em>{texts.fbpageButtonSub}</em>
        </a>
      </div>
    </div>
  </Section>
)

export default Works