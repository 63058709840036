import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
// import Img from "gatsby-image/withIEPolyfill"

export default () => {
    
    const data = useStaticQuery(graphql`
        query {
            file(relativePath: {eq: "png/steering-wheel-b50.png"}) {
                childImageSharp {
                    fluid(quality:100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }`
    )

    return (
        <div className="landing__image">
            <Img
                fluid={data.file.childImageSharp.fluid}
                fadeIn={true}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="Kormánybőrözés"
            />
        </div>
    );
} 