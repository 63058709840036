import React from "react"
import HamburgerIcon from "../images/svg/hamburger.svg"
import CloseIcon from "../images/svg/close.svg"

const Hamburger = ({onClick,mobileMenuOpened}) => {
    return (
        <div
            className="hamburger-icon"
            onClick={onClick}
            onKeyDown={onClick}
            role="button"
            tabIndex="0"
        >
            {!mobileMenuOpened&&<HamburgerIcon />}
            {mobileMenuOpened&&<CloseIcon />}
        </div>
    )
}

export default Hamburger