import React from "react"
import Section from "./section"
import PricesImage from "./pricesImage"
import Arrow from "./arrow"

class Prices extends React.Component {

  state = {
    itemWidth: 210,
    minScrollIndex: 0,
    maxScrollIndex: 2,
    scrollIndex: 0
  }

  componentDidMount() {
    this.pricesListScroll = document.querySelector(".prices__list--visible");
    const {minScrollIndex} = this.state;

    const itemWidth = document.querySelector(".prices__list--visible > .prices__list > li").offsetWidth;
    const itemCount = this.props.priceList.length;
    const visibleWidth = this.pricesListScroll.offsetWidth;
    const visibleItemsCount = visibleWidth / itemWidth;
    const maxScrollIndex = itemCount - visibleItemsCount;

    this.pricesListScroll.addEventListener('scroll', (ev) => {
      clearTimeout(this.debounced);
      this.debounced = setTimeout(()=>{
        const scrollLeft = ev.target.scrollLeft;
        let newScrollIndex = minScrollIndex;

        for( let i = minScrollIndex; i < maxScrollIndex; i++ ) {
          if ( scrollLeft > ( i * itemWidth + itemWidth / 2 ) ) {
            newScrollIndex = i+1;
          }
        }

        this.setState((state)=>({
            scrollIndex: newScrollIndex,
            itemWidth,
            maxScrollIndex
          }),
          this.scrollPrices
        )
      },100);
    });
  }

  render () {
    const {
      texts,
      emailAddress,
      emailBody,
      emailSubject,
      priceList
    } = this.props;

    return (
      <Section name="prices">
        <em className="prices__bgtext">{texts.bgtext}</em>
        <div className="prices__wrap">
          <div className="prices_voucher_box --mobile">
            <ul className="prices__list">
                <li className="featured">
                  <span className="label">Ajándékutalvány</span>
                  <span className="price">ajándékozza a vezetés új élményét szerette számára</span>
                </li>
            </ul>
          </div>
          <div className="prices__redbox">
            <p>
              <b>{texts.redBoxTitle}</b>
              {texts.redBoxContent}
            </p>
            <a
              href={`mailto:${emailAddress}?subject=${emailSubject}&body=${emailBody}`}
              target="_blank"
              rel="noreferrer"
              className="button"
            >
              {texts.redBoxButtonLabel}
            </a>
          </div>
          <div className="prices__list-container">
            {this.state.scrollIndex>0&&<div
              className="arrow arrow-left"
              onClick={this.handleLeftClick}
              onKeyDown={this.handleLeftClick}
              role="button"
              aria-label="scroll left"
              tabIndex="0"
              >
              <Arrow extent="h"/>
            </div>}{this.state.scrollIndex<=0&&<div className="arrow arrow-left ghost"></div>}

            {this.state.scrollIndex<this.state.maxScrollIndex&&<div
              className="arrow arrow-right"
              onClick={this.handleRightClick}
              onKeyDown={this.handleRightClick}
              role="button"
              aria-label="scroll right"
              tabIndex="0"
            >
              <Arrow extent="h" flipped/>
            </div>}
            {this.state.scrollIndex>=this.state.maxScrollIndex&&<div className="arrow arrow-right ghost"></div>}


            <div className="prices__list--visible">
              <ul className="prices__list">
                {priceList.map((item,i)=>(
                  <li key={i}>
                    <span className="label">{item.label}</span>
                    <span className="price">{item.price}</span>
                  </li>
                ))}
              </ul>
            </div>
              <ul className="prices__list">
                  <li className="featured">
                    <span className="label">Ajándékutalvány</span>
                    <span className="price">ajándékozza a vezetés új élményét szerette számára</span>
                  </li>
              </ul>
          </div>
          <PricesImage />
        </div>
      </Section>
    )
  }

  handleLeftClick = (target) => {
    this.setState((state,props)=>({
        scrollIndex: Math.max(state.scrollIndex-1,state.minScrollIndex)
      }),
      this.scrollPrices
    )
  }
  
  handleRightClick = (target) => {
    this.setState((state,props)=>({
        scrollIndex: Math.min(state.scrollIndex+1,state.maxScrollIndex)
      }),
      this.scrollPrices
    )
  }

  scrollPrices = () => {
    let { itemWidth, scrollIndex } = this.state;
    this.pricesListScroll.scroll({
      top: 0,
      left: itemWidth*scrollIndex,
      behavior: 'smooth'
    })
  }
}

export default Prices