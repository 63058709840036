import React from "react"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Arrow from "./arrow"

const Menu = ({elements,activeMenu}) => {
    return (
        <nav className="header__menu">
            <ul>
                {Array.isArray(elements)&&elements.map(el=>{
                    const elId = el.target.replace('#','');
                    const className = `menu__item${elId === activeMenu ? " menu__item--active" : ""}`;
                    return (<li className={className} key={elId}>
                                <a href={el.target} onClick={(ev) => {ev.preventDefault(); scrollTo( `${el.target}` )} } style={{width: el.w}}>
                                    {el.name}
                                </a>
                                <Arrow extent="v"/>
                            </li>)
                })}
            </ul>
        </nav>
    );
}

export default Menu