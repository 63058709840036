import React from "react"
import Section from "./section"
import LandingImage from "./landingImage";

const Landing = ({texts}) => (
  <Section
    name="landing"
  >
    <LandingImage />
    <div className="landing__texts">
      <p>{texts.p}</p>
      <h1>LEATHE<b>R</b>ING</h1>
      <h3>{texts.h3}</h3>
    </div>
</Section>
)

export default Landing