import React from "react"
import LogoWhite from "../images/svg/logo-white.svg"
import LogoColor from "../images/svg/logo-color.svg"

const Logo = ({whiteLogo,onClick}) => {
    return (
        <div
            className="header__logo"
            onClick={onClick}
            onKeyDown={onClick}
            role="button"
            tabIndex="0"
        >
            {whiteLogo&&<LogoWhite />}
            {!whiteLogo&&<LogoColor />}
        </div>
    )
}

export default Logo