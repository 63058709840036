import React from "react"
import Section from "./section"

import LeatheringImage from "./leatheringImage";
import Appointment from "../images/svg/appointment.svg"
import Hours from "../images/svg/hours.svg"
import Waiting from "../images/svg/waiting.svg"
import Complicated from "../images/svg/complicated.svg"
import Wheels from "../images/svg/wheels.svg"
import Shifters from "../images/svg/shifters.svg"

const Leathering = ({texts}) => (
  <Section
    name="leathering"
  >
    <div className="leathering__wrap">
      <LeatheringBlockElement caption={texts.appointment}><Appointment /></LeatheringBlockElement>
      <LeatheringBlockElement caption={texts.hours}><Hours /></LeatheringBlockElement>
      <LeatheringBlockElement caption={texts.waiting}><Waiting /></LeatheringBlockElement>
      <LeatheringBlockElement caption={texts.complicated}><Complicated /></LeatheringBlockElement>
      <LeatheringBlockElement caption={texts.wheels} large><Wheels /></LeatheringBlockElement>
      <LeatheringBlockElement caption={texts.shifters} large><Shifters /></LeatheringBlockElement>
      <div className="leathering__lead">
        <h3>{texts.h3}</h3>
        <p>{texts.p}</p>
      </div>
      </div>
    <LeatheringImage />
  </Section>
)

export default Leathering

const LeatheringBlockElement = (props) => (
    <figure className={["leathering__be",(props.large?"large":"")].join(" ")}>
      <div className="be__top">
        {props.children}
      </div>
      <div className="be__bottom">
        <figcaption>{props.caption}</figcaption>
      </div>
    </figure>
);