import React from "react"
import Section from "./section"
import AboutImage from './aboutImage'

const About = ({texts}) => (
  <Section
    name="about"
  >
    <div className="about__wrap">
      <div className="about__whitebox">
        <AboutImage />
        <span className="about__badge">
          <p><b>{texts.badge.b}</b>{texts.badge.p}</p>
        </span>
        <p className="about__text --left">
          <em>{texts.whitebox.head}</em>
          {texts.whitebox.content.left}
        </p>
        <p className="about__text --right">
          {texts.whitebox.content.right}
          <em>{texts.whitebox.tail}</em>
        </p>
      </div>
    </div>
  </Section>
)

export default About